import React from 'react';
import { Button } from 'react-bootstrap'
import styles from "./homeModal.module.css"
import HomeModal from "./HomeModal"

function Modal(props) {
    const [modalShow, setModalShow] = React.useState(false)
  
    return (
      <>
         <Button className={`btn-block ${styles.letterButton}`}
            onClick={() => setModalShow(true)}
         >{props.signUpText}</Button>

         <HomeModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
    )
  }

  export default Modal;