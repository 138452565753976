import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const WaterSectionSwimmer = ({ className, children }) => (
    <StaticQuery
        query={graphql`
            {   waterSwimmerBackground: file(relativePath:{eq:"home-swimmer-bg.jpg"}) {
            childImageSharp {
              fluid(quality:90, maxWidth:1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        `}
    render={data => {
        const imageData = data.waterSwimmerBackground.childImageSharp.fluid
    
    return (
    <BackgroundImage
        tag="section"
        className={className}
        fluid={imageData}
        backgroundColor={`#000000`}
    >
        { children }
    </BackgroundImage>
    )
}}
/>
)

const StyledBackgroundSection = styled(WaterSectionSwimmer)`
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 907px;
    overflow: hidden;
`

export default StyledBackgroundSection;