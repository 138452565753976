import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from "./PartnerBlock.module.css"
import IdahoNationalLabs from '../../images/Idaho_National_Laboratory_logo.svg'
import CommunityFoundation from '../../images/community-foundation-logo.svg'

const PartnerBlock = () => {
    // useStaticQuery because we're querying in a component and not a page
    const data = useStaticQuery(graphql`
    query {
        driggs: file(relativePath: { eq: "city-of-driggs.png" }) {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
        }
        usaSwimming: file(relativePath: { eq: "USA-Swimming-Foundation-logo.png" }) {
            childImageSharp {
                fixed(width: 200) {
                ...GatsbyImageSharpFixed
                }
            }
        }
    }
`)

return (    
        <Container fluid className={styles.greenBox}>
            <Container style={{
                marginTop: '6rem',
                marginBottom: '6rem'
            }}>
                <Row className="align-items-center" style={{
                    height: '100%',
                }}>
                    <Col>
                    <h1 className={styles.headline}>Our Partners &amp; Sponsors</h1>
                    </Col>
                </Row>
                <Row className="my-4" style={{
                    height: '100%',
                    alignItems: 'center'
                }}>
                    <Col md={3} className="text-center mb-4">
                        <a href="https://www.driggsidaho.org" target="_blank" rel="noopener noreferrer"><Img fixed={data.driggs.childImageSharp.fixed} alt="City of Driggs" /></a>
                    </Col>
                    <Col md={3} className="text-center mb-4">
                    <a href="https://inl.gov/" target="_blank" rel="noopener noreferrer"><img src={IdahoNationalLabs} alt="Idaho National Laboratory" style={{ width: '100%' }}/></a>
                        
                    </Col>
                    <Col md={3} className="text-center mb-4">
                    <a href="https://cftetonvalley.org/" target="_blank" rel="noopener noreferrer"><img src={CommunityFoundation} alt="Community Foundation of Teton Valley" style={{ width: '100%'}} /></a>
                    </Col>
                    <Col md={3} className="text-center mb-4">
                        <a href="https://www.usaswimming.org/foundation#saving-lives-through-swim-lessons" target="_blank" rel="noopener noreferrer"><Img fixed={data.usaSwimming.childImageSharp.fixed} alt="USA Swimming Foundation" /></a>
                    </Col>
                </Row>
            
            </Container>
            
        </Container>
);
    
}

export default PartnerBlock;