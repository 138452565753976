import React from "react"
import { Container } from "react-bootstrap"

const WaterBlock = ( {children}) => {

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "auto",
          padding: "5% 0 5% 0",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >            
          <Container>
            {children}
          </Container>
        </div>
      </div>
    </div>
  )
}

export default WaterBlock
