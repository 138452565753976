import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Card, CardDeck, Button } from 'react-bootstrap';
import "./HomeCards.css"
import LifeJacketLoanerModal from './LifeJacketLoanerModal';

function Modal(props) {
  const [modalShow, setModalShow] = React.useState(false)
  return (
    <>
      <Button className="card-button" onClick={() => setModalShow(true)}>Learn More</Button>     
      <LifeJacketLoanerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalTitle={props.modalTitle}
        modalContent={props.modalContent}
      />
    </>
  )
}

const HomeCards = () => {
     // useStaticQuery because we're querying in a component and not a page
  const data = useStaticQuery(graphql`
  query {
    feasibilityStudy: file(relativePath: { eq: "feasibility-study.jpg" }) {
      childImageSharp {
        fixed(width: 170, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    siteSelection: file(relativePath: { eq: "site-selection.jpg" }) {
        childImageSharp {
          fixed(width: 170, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pfdLoaner: file(relativePath: { eq: "pfd-loaner.jpg" }) {
        childImageSharp {
          fixed(width: 170, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      portablePool: file(relativePath: { eq: "portable-pool.jpg" }) {
        childImageSharp {
          fixed(width: 170, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
  }
`)
    return (
<CardDeck  style={{
        marginBottom: '6rem'
      }} >
<Card>
  <Img fixed={data.feasibilityStudy.childImageSharp.fixed} className="rounded mx-auto d-block" />
  <Card.Body>
    <Card.Title>Feasibility Study</Card.Title>
    <Card.Text>
    Includes a rough order of magnitude cost for the construction and operation to inform the planning, design, construction and sustainability of the facility.<br /><small>(Opens as PDF in a new tab/window).</small>
    </Card.Text>
  </Card.Body>
  <Card.Footer>
  <a href="https://assets.ctfassets.net/kjisl26qqle3/4IPp4eQEpeJ30CM2TyiN74/412e8d7802d6e770cf3899fd75bb36d1/2019-11-12_TVA_Feasibility_Executive_Summary.pdf" target="_blank" rel="noreferrer noopener"><Button className="card-button">Learn More</Button></a>
  </Card.Footer>
</Card>

<Card>
<Img fixed={data.portablePool.childImageSharp.fixed} className="rounded mx-auto d-block" />
  <Card.Body>
    <Card.Title>Summer Pool Program</Card.Title>
    <Card.Text>
    Summer pool program providing beginner swim lesson and aquatics activities to the Teton Valley Community.
    </Card.Text>
  </Card.Body>
  <Card.Footer>
    <Link to="/programs-events"><Button className="card-button">Learn More</Button></Link>
  </Card.Footer>
</Card>

{/* <Card>
<Img fixed={data.pfdLoaner.childImageSharp.fixed} className="rounded mx-auto d-block" />
  <Card.Body>
    <Card.Title>Life Jacket Loaners</Card.Title>
    <Card.Text>
    Life Jacket Loaner Stations: Personal flotation devices (PDFs) available for use at three Teton River launch ramps! 
    </Card.Text>
  </Card.Body>
  <Card.Footer>
    <Modal><Button className="card-button">Learn More</Button></Modal>
  </Card.Footer>
</Card> */}

<Card>
<Img fixed={data.siteSelection.childImageSharp.fixed} className="rounded mx-auto d-block" />
  <Card.Body>
    <Card.Title>Site Aquisition</Card.Title>
    <Card.Text>
    Over 7 acres have been donated to the City of Driggs for the future aquatic facility. The site is located just north of the 5th Street Skatepark near Ski Hill Rd and 5th St in Driggs.
    </Card.Text>
  </Card.Body>
  <Card.Footer>
  <Link to="/the-project"><Button className="card-button">Learn More</Button></Link>
  </Card.Footer>
</Card>
</CardDeck>
    );
}

export default HomeCards;


