import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Container, Row, Col, Button } from "react-bootstrap"
import SEO from "../components/seo"

import WaterSection from "../components/WaterSection/WaterSection"
import BlueBlock from "../components/ContentBlocks/BlueBlock"
import HomeCards from "../components/HomeCards/HomeCards"
import WaterSectionSwimmer from "../components/WaterSection/WaterSectionSwimmer"
import WaterBlock from "../components/ContentBlocks/WaterBlock"
import HomeBlogBlock from "../components/HomeBlogBlock/HomeBlogBlock"
import HomeNewsletterBlock from "../components/HomeNewsletterBlock/HomeNewsletterBlock"
import PartnerBlock from "../components/PartnerBlock/PartnerBlock"

import HomeModalButton from "../components/HomeModal/HomeModalButton"
import GivingTuesdayModalButton from "../components/GivingTuesdayModal/GivingTuesdayModalButton"
import styles from "../components/HomeModal/homeModal.module.css"
import GivingTuesdayModal from "../components/GivingTuesdayModal/GivingTuesdayModal"
import AnnoucementModal from "../components/AnnouncementModal/AnnoucementModal"
import PortablePoolModalButton from "../components/PortablePoolModal/PortablePoolModalButton"
import PortablePoolModal from "../components/PortablePoolModal/PortablePoolModal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import Helmet from "react-helmet"


// Announcement Modal
function Modal() {
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    //if (!sessionStorage.popupModal) {
      const modalTimer = setTimeout(() => {
        setModalShow(true)
        //sessionStorage.popupModal = 1
      }, 1000 )
      return () => {
        clearTimeout(modalTimer)
      }
     //}
  }, [])

  return (
    <>
    {/* <GivingTuesdayModalButton
        signUpText="Donate!"
        className={styles.letterButton}
      /> */}
      <AnnoucementModal show={modalShow} onHide={() => setModalShow(false)}
        modalTitle={<><span>Aquatic Recreation Center Survey</span></>}
        modalBody={<><p>Please fill out the survey below to provide your input on the future Teton Valley aquatic recreation center!</p>
        <a href="https://forms.gle/U2Wh1f8dm5hzaNnu6" target="_blank" rel="noopener noreferrer"><Button>Take the Survey Now <FontAwesomeIcon icon={faAngleRight} /></Button> </a></>}
      />
    </>
  )
}

function PortablePoolSignupModal() {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <PortablePoolModalButton 
        signUpText="Sign Up For Portable Pool Swim Lessons"
      />
      <PortablePoolModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}


const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    {/* <Helmet defer={false}>
      <script src='https://www.google.com/recaptcha/api.js?r=878293189'></script>
    </Helmet> */}
    <div>
      <WaterSection>
        <BlueBlock>
        <Row  className="text-center">
              <Col sm={12}>
                <h1
                  style={{
                    color: "#87C5DE",
                  }}
                >
                  Welcome to Teton Valley Aquatics
                </h1>
                <h2
                  style={{
                    color: "white",
                  }}
                >
                  Our Mission is to build a healthier community through the
                  power of water.
                </h2>
                <h2
                  style={{
                    color: "white",
                    marginBottom: "2.5rem"
                  }}
                >
                  Join in our journey of pursuing the dream of year-round water access for our Teton Valley community!
                </h2>
                {/* <h2
                  style={{
                    color: "white",
                  }}
                >
                 Summer Swim Lesson Registration Opens April 1, 2022
                </h2>
                <Row className="mb-2 mt-4">
                  <Col>
                  <Link to="/summer-swim-lessons" className="btn-link">
                  <Button className={`btn-block ${styles.letterButton}`}>
                  Summer Swim Lesson Information <FontAwesomeIcon icon={faAngleRight} aria-hidden="true" /></Button></Link>
                  </Col>
                </Row>
                <Row className="mb-2 mt-4">
                  <Col>
                  <Link to="/summer-swim-lessons-espanol" className="btn-link">
                  <Button className={`btn-block ${styles.letterButton}`}>
                  Clases de natación en verano <FontAwesomeIcon icon={faAngleRight} aria-hidden="true" /></Button></Link>
                  </Col>
                </Row> */}
                
                {/* <Row className="text-center">
                  <Col className="my-4 text-center d-flex justify-content-center"> */}
                    {/* <PortablePoolSignupModal /> */}
                    {/* <HomeModalButton
                      signUpText="Teton Valley Aquatics Portable Pool Policy"
                    /> */}
                    
                     <Modal />
                    {/* <a href="https://tetonvalleyaquatics.app.neoncrm.com/forms/general-donations" target="_blank" rel="noopener noreferrer"><Button className={'donate-button'}>
                      Donate Now</Button></a> */}
                  {/* <Link to="/donate" style={{textDecoration: 'none'}}><Button className="donate-button" variant="">Donate Now</Button></Link> */}
                  {/* </Col>
                </Row>
              </Col> */}
              {/* <Col>
                <Img
                  fluid={data.jessicaPozzi.childImageSharp.fluid}
                  alt="Jessica Pozzi"
                  className="rounded"
                />*/}
              </Col> 
            </Row>
        </BlueBlock>
      </WaterSection>
    </div>
    <Container>
      <Row style={{
        marginTop: '6rem',
        marginBottom: '6rem'
      }}>
        <Col>
          <h1 className="green-headline">Where we have been, what we are doing, and where we are going</h1>
        </Col>
      </Row>
      
      <HomeCards />

   </Container>

   <div>
     <WaterSectionSwimmer>
      <WaterBlock>
        <Row className="my-4" style={{
          marginBottom: '5%'
        }}>
          <Col className="text-center">
            <h1  className="swimmer-headline">Teton Valley Aquatics Making A Difference</h1>
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm={3} className="text-center">
          <h1 className="swimmer-block">300</h1>
          <p className="swimmer-block-txt">Josh the Otter Participants</p>
          </Col>
          <Col sm={3} className="text-center">
          <h1 className="swimmer-block">200+</h1>
          <p className="swimmer-block-txt">2021 Swim Lessons</p>
          </Col>
          <Col sm={3} className="text-center">
          <h1 className="swimmer-block">100</h1>
          <p className="swimmer-block-txt">PFDs at Loaner Stations</p>
          </Col>
          <Col sm={3} className="text-center">
          <h1 className="swimmer-block">700+</h1>
          <p className="swimmer-block-txt">Community Members Reached</p>
          </Col>
        </Row>
      </WaterBlock>
    </WaterSectionSwimmer>
   </div>
   <div style={{
     marginTop: '6rem',
     marginBottom: '6rem'
   }}>
     <HomeBlogBlock />
   </div>
   <div>
     <HomeNewsletterBlock />
   </div>
   <div>
     <PartnerBlock />
   </div>
  </Layout>
)

export const data = graphql`
query {
  jessicaPozzi: file(relativePath: { eq: "jessica-pozzi.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 982, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`


export default IndexPage
