import React from "react"
import { Container } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

const BlueBlock = ( {children}) => {
    // useStaticQuery because we're querying in a component and not a page
  const data = useStaticQuery(graphql`
    query {
      jessicaPozzi: file(relativePath: { eq: "jessica-pozzi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "#003366",
          padding: "5% 0 5% 0",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >            
          <Container>
            {children}
          </Container>
        </div>
      </div>
    </div>
  )
}

export default BlueBlock
