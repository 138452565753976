import React from 'react';
import { Button } from 'react-bootstrap'
import styles from "../HomeModal/homeModal.module.css"

import PortablePoolModal from "./PortablePoolModal"

function Modal(props) {
    const [modalShow, setModalShow] = React.useState(false)
  
    return (
      <>
         <Button className={`btn-block ${styles.letterButton}`}
            onClick={() => setModalShow(true)}
         >{props.signUpText}</Button>

         <PortablePoolModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
    )
  }

  export default Modal;